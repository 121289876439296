.order-review-mfe {
  background-color: color('primary-light');
  border-radius:0px;
  display: flex;
  flex-direction: column;
  margin: rem(10) rem(10) rem(30) rem(10);

  @include media-query(700) {
    margin: rem(13) auto rem(30) auto;
  }
  @include media-query(1025) {
    border: 1px solid color('tertiary-light');
    margin: rem(40) 0 rem(40) 0;
  }
.buyer-info-section{
  padding: 0 2rem;

  @include media-query(540){
    padding: 0 5.5rem;
  }
  @include media-query(700){
    margin-top: 2rem;
  }
  @include media-query(1025){
    padding: 0 rem(40) rem(40) rem(40);
  }
  .order-review-heading {
    text-align: center;
    text-transform: capitalize;
    padding: rem(30) 0 rem(20) 0;
    @include media-query(1025){
      padding: rem(20) 0;
    }
  }
  .order-pickup-location{
    padding: 0 rem(20) rem(20) rem(20);
    @include media-query(1025){
      padding: 0 rem(40) rem(20) rem(40);
    }
  }
  .shipment-info {
    h3 {
      margin-bottom: rem(20);
    }
  }
  .shipping-methods {
    border-top: 1px solid color('tertiary-light');
    margin-top: 2rem;
    padding-top: 2rem;
    @include media-query(700){
      margin-top: 2rem;
    }
  }
  .payment-section {
    padding-right: rem(40);
    @include media-query(1025){
      border-top: 1px solid color('tertiary-light');
      margin-top: 2rem;
      padding-top: 2rem;
    }
    .desktop-paymentlabel {
      display: none;
      @include media-query(1025){
        display: block;
        margin-bottom: rem(20);
      }
    }
    .payment-icon {
      margin-left: 0;
    }
  }
}
  

  .estimated-delivery-date {
    margin-top: 2.5rem;
  }
}

.alt-pickup {
  .buyer-info-section {
    @include media-query(1025) {
      padding: 0;
    }
    .payment-section {
      .payment-info {
        margin-left: 4rem;
      }
    }
  }
}
