.apple-pay {
  border: 1px solid color('neutral-900');
  box-sizing: border-box;
  display: block;
  margin-top: 2rem ;
  margin-bottom: 2rem;
  background-color: color('neutral-000');
  background-image: -webkit-named-image(apple-pay-logo-black);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 40% 45%;
  font-size: rem(15);
  letter-spacing: rem(0.6);
  line-height: rem(48);
  min-height: rem(50);
  text-transform: capitalize;
  width: rem(125);
}