.checkout-page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  &.non-loyalty.applepay-express {
    @include media-query(0, 700) {
      flex-wrap: wrap;
    }
    .button-container {
      justify-content: space-between;
    }
  }
  &.non-loyalty.applepay-express.loggedin-view{
    @include media-query(0, 700) {
      flex-wrap: nowrap;
    }
  }
  //to remove this media query once all the changes from CRS is migrated
  @include media-query(1025) {
    margin-bottom: rem(30);
  }

  .header-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
  }
  
  .button-container {
    display: flex;
    justify-content: flex-end;
    gap: rem(10);

    .sign-in-join {
      margin-right: rem(10);
      .button {
        margin: rem(20) rem(0);
      }
    }
    
    .paypal-button { 
      width: rem(125);
      img {
        height: 2.5rem;
        width: 9.5rem;
      }
    }
  
    .apple-pay-checkout {
      width: rem(125);
      margin-right: rem(10);
      background-size: 50%;
    }

    @include media-query(0, 700) {
      width: 100%;
    }
  }
}